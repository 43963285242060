exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-template-tsx": () => import("./../../../src/templates/AboutUsTemplate.tsx" /* webpackChunkName: "component---src-templates-about-us-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/ContactTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/HomeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-legal-template-tsx": () => import("./../../../src/templates/LegalTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-template-tsx" */),
  "component---src-templates-machine-template-tsx": () => import("./../../../src/templates/MachineTemplate.tsx" /* webpackChunkName: "component---src-templates-machine-template-tsx" */),
  "component---src-templates-machines-index-template-tsx": () => import("./../../../src/templates/MachinesIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-machines-index-template-tsx" */),
  "component---src-templates-news-and-projects-template-tsx": () => import("./../../../src/templates/NewsAndProjectsTemplate.tsx" /* webpackChunkName: "component---src-templates-news-and-projects-template-tsx" */),
  "component---src-templates-news-index-template-tsx": () => import("./../../../src/templates/NewsIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-news-index-template-tsx" */),
  "component---src-templates-sector-template-tsx": () => import("./../../../src/templates/SectorTemplate.tsx" /* webpackChunkName: "component---src-templates-sector-template-tsx" */),
  "component---src-templates-service-template-tsx": () => import("./../../../src/templates/ServiceTemplate.tsx" /* webpackChunkName: "component---src-templates-service-template-tsx" */),
  "component---src-templates-subservice-template-tsx": () => import("./../../../src/templates/SubserviceTemplate.tsx" /* webpackChunkName: "component---src-templates-subservice-template-tsx" */)
}

